<template>
  <div id="clients">
    <div class="lists-header">
      <v-toolbar flat >
        <v-text-field :value="filter.search" @change="value => filter.search = value" placeholder="クライアント名" filled rounded prepend-inner-icon="mdi-magnify" clearable hide-details dense ></v-text-field>
        <v-spacer />
        <v-btn elevation="0" color="primary" @click="$router.push({ name: 'admin:client_new_form'})"><v-icon left >mdi-plus-circle</v-icon>新規登録</v-btn>
        <vue-json-to-csv :json-data="exportOptions.data" :labels="exportOptions.labels" :csv-title="exportOptions.filename" @success="val => downloadCSVSuccess(val)">
          <v-btn elevation="0" @click="downloadCSV" :disabled="clients.length == 0" text><v-icon left >mdi-download-circle-outline</v-icon>CSVダウンロード</v-btn>
        </vue-json-to-csv>
      </v-toolbar>
    </div>
    <v-container class="">
        <v-card>
          <!-- <v-card-text> -->
          <v-data-table :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="clients" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" class="clickable" @click:row="goDetail">
            <!-- 列：クライアント名 -->
            <template v-slot:item.end_company_name="{ item }">
              <template v-if="item.end_company_name">{{ item.end_company_name }}</template>
            </template>
            <!-- 列：保守プラン -->
            <template v-slot:item.current_end_user_plan="{ item }">
              <template v-if="item.current_end_user_plan">
                <span class="d-inline-flex align-center">
                  <v-img v-if="item.current_end_user_plan.plan_mst.contract_model === 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1" />
                  <v-img v-else src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1" />
                  {{ item.current_end_user_plan.plan_mst.plan_name }}
                </span>
              </template>
            </template>
            <!-- 列：契約満了日 -->
            <template v-slot:item.end_contract_date="{ item }">
              <template v-if="item.current_end_user_plan">{{ item.current_end_user_plan.end_contract_date | moment("YYYY/MM/DD(ddd)") }}</template>
            </template>
            <!-- 列：残りポイント -->
            <template v-slot:item.remainder_point="{ item }">
              <template v-if="item.current_end_user_plan">
                <template v-if="item.current_end_user_plan.plan_mst.contract_model == 'point'">{{ item.current_end_user_plan.remainder_point }}<span class="unit">Pt</span></template>
                <template v-else><span class="text-body-2">∞</span></template>
              </template>
            </template>
            <!-- 列：アクション -->
            <template v-slot:item.action="{ item }">

              <v-menu offset-y min-width="160">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item :disabled="!item.current_end_user_plan" @click.stop="addNewRequest(item.id.toString())">
                    <v-list-item-icon><v-icon small>mdi-checkbox-multiple-marked-outline</v-icon></v-list-item-icon>新規依頼
                  </v-list-item>
                  <v-list-item @click.stop="goRequests(item)" class="mr-1">
                    <v-list-item-icon><v-icon small>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>依頼一覧
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <!-- </v-card-text> -->
        </v-card>
    </v-container>
  <new-request ref="new_request" v-bind:endId="currentClientId"></new-request>
  </div>
</template>

<script>
import NewRequest from '../../components/NewRequest.vue'
import axios from '@/plugins/axios'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'Clients',
  components: {
    NewRequest,
    VueJsonToCsv
  },
  data: () => ({
    key: {
      altKeyOn: false,
      shiftKeyOn: false,
      ctrlKeyOn: false,
      metaKeyOn: false
    },
    options: {},
    totalPassengers: 3,
    loading: false,
    currentClientId: '',
    filter: {
      search: ''
    },
    headers: [
      {
        text: 'クライアント名',
        value: 'end_company_name'
      },
      {
        text: '契約保守プラン',
        value: 'current_end_user_plan'
      },
      {
        text: '契約満了日',
        width: 140,
        value: 'end_contract_date',
        align: 'center'
      },
      {
        text: 'Pt 残',
        width: 90,
        value: 'remainder_point',
        align: 'end'
      },
      {
        text: '',
        width: 80,
        value: 'action',
        sortable: false,
        align: 'end'
      }
    ],
    clients: [],
    exportOptions: {
      data: [],
      labels: {
        end_company_name: { title: 'クライアント名' },
        current_end_user_plan: { title: '契約保守プラン' },
        end_contract_date: { title: '契約満了日' },
        remainder_point: { title: '残りpt' }
      },
      filename: ''
    }
  }),
  watch: {
    $route (to, from) {
      this.readDataFromAPI()
    },
    filter: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      },
      deep: true
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  },
  methods: {
    goDetail: function (value) {
      const routeOption = { name: 'admin:client_show', params: { id: value.id } }
      if (this.key.altKeyOn === true || this.key.shiftKeyOn === true || this.key.ctrlKeyOn === true || this.key.metaKeyOn === true) {
        window.open(this.$router.resolve(routeOption).href)
      } else {
        this.$router.push(routeOption)
      }
    },
    goRequests (value) {
      this.$router.push({ name: 'admin:request_index', params: { fillter: 'all' }, query: { end_user_id: value.id, end_company_name: value.end_company_name } })
    },
    downloadCSV () {
      this.clients.forEach((client) => {
        this.exportOptions.data.push({
          end_company_name: client.end_company_name ? client.end_company_name.split('"').join('""') : '',
          current_end_user_plan: client.current_end_user_plan?.plan_mst.plan_name ? client.current_end_user_plan?.plan_mst.plan_name.split('"').join('""') : '',
          end_contract_date: client.current_end_user_plan?.end_contract_date,
          remainder_point: (client.current_end_user_plan && client.current_end_user_plan?.plan_mst?.contract_model === 'point' ? client.current_end_user_plan?.remainder_point : '')
        })
      })
      this.exportOptions.filename = 'clients_' + this.$moment().format('YYYYMMDDHHmmss')
    },
    downloadCSVSuccess () {
      this.exportOptions.data.splice(0)
    },
    addNewRequest (id) { // open new request dialog (component)
      this.currentClientId = id
      this.$refs.new_request.open()
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'id' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.$route.params.fillter) {
        queryParams.quicksearch = this.$route.params.fillter
      }
      if (this.filter.search) {
        queryParams.query = this.filter.search
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user`, {
        params: queryParams
      })
        .then(res => {
          this.loading = false
          this.clients = res.data.data
          this.totalPassengers = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created: function () {
    const self = this

    // キーイベント監視
    self.keyDownHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKey
    }
    self.keyUpHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKeyOn
    }
    window.addEventListener('keydown', self.keyDownHandler)
    window.addEventListener('keyup', self.keyUpHandler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('keyup', this.keyUpHandler)
  }
}
</script>
